<template>
    <section class="sidebar-stores-two">
        <div class="content-head">
            <h3 class="heading">
                {{ title }}
            </h3>
            <NuxtLink v-if="path" :to="path" rel="follow">Ver más</NuxtLink>
        </div>

        <ul v-if="info.length > 0" class="sidebar-stores-two__popular-shops">
            <li v-for="(item, index) in info" :key="index">
                <NuxtLink :to="`${path}/${item.slug}`" rel="follow" :title="'Cupones ' + item.name">
                    <picture>
                        <img
                            v-if="item.image"
                            :src="item.image.url"
                            :alt="item.name"
                            :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                    /></picture>
                    {{ item.name }}
                </NuxtLink>
            </li>
        </ul>
        <p v-else class="no-similar">No hay tiendas populares disponibles</p>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'SidebarStoresTwo',
    props: {
        path: {
            type: String,
            required: true,
        },
        info: {
            type: Array as PropType<(Models.Store | Models.Category)[]>,
            required: true,
        },
        title: {
            type: String,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return {
            defaultImage: defaultImages.store,
        }
    },
    computed: {
        icon(): object {
            // @ts-ignore
            return this.$assets[this.iconColor][this.iconName]
        },
    },
})
</script>

<style lang="postcss" scoped>
.sidebar-stores-two {
    .content-head {
        @apply mb-2 flex justify-between;
        .heading {
            @apply text-lg font-semibold;
        }
        a {
            @apply self-center text-sm font-medium text-site-primary hover:underline;
        }
    }

    &__popular-shops {
        @apply flex flex-wrap;
        li {
            @apply m-1 block;
            a {
                @apply flex items-center whitespace-nowrap rounded-xl border border-site-primary border-opacity-60 bg-white px-3 py-1.5 font-medium shadow-site-primary-shadow;
                picture {
                    @apply mr-1 block h-8 w-8;
                    img {
                        @apply h-full w-full object-contain;
                    }
                }
            }
        }
    }
    .no-similar {
        @apply py-4 text-center text-site-gray;
    }
}
</style>
