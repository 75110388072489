<template>
    <div ref="$scrollRoot" class="scroll-y-container">
        <slot />
    </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['onNextPage'])

const props = defineProps({
    page: {
        type: Number,
    },
    stop: {
        type: Boolean,
    },
    loading: {
        type: Boolean,
    },
})

const $scrollRoot = ref() as Ref<HTMLDivElement>

onMounted(() => {
    nextTick(() => {
        window.addEventListener('scroll', handleScroll)
    })
})

const stopValue = computed(() => props.stop || false)

watch(stopValue, (stop) => {
    if (stop) {
        removeEventListener('scroll', handleScroll)
    }
})

onUnmounted(() => {
    removeEventListener('scroll', handleScroll)
})

const handleScroll = () => {
    const currentScrollHeight = window.innerHeight + window.scrollY

    const coords = $scrollRoot.value.getBoundingClientRect()

    const infiniteScrollThreshold = ($scrollRoot.value.offsetTop + coords.height) * 0.9

    if (currentScrollHeight > infiniteScrollThreshold && !props.loading) {
        emit('onNextPage')
    }
}
</script>

<style lang="postcss" scoped>
.scroll-y-container {
    @apply flex flex-col;
}

.scroll-y-container__loading {
    @apply mt-5 text-center font-semibold;
}
</style>
