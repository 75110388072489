<template>
    <div v-if="currentPage || meta.last_page === currentPage" class="infinite-scroll-footer">
        <div class="infinite-scroll-footer__container">
            <button type="button" class="infinite-scroll-footer__button">
                <img :src="$assets.primary.arrowDown" alt="" />
                <span class="hidden md:block">Ir arriba</span>
            </button>

            <div class="infinite-scroll-footer__pagination">
                <button type="button" class="first-page">1</button>

                <span>...</span>

                <button type="button" class="prev-page">
                    <img :src="$assets.primary.arrowLeft" alt="" />
                </button>

                <button type="button" class="current-page">
                    <span class="hidden md:inline">Página</span> {{ currentPage }}
                </button>

                <button type="button" class="next-page">
                    <img :src="$assets.primary.arrowRight" alt="" />
                </button>

                <span>...</span>

                <button type="button" class="last-page">{{ meta.last_page }}</button>
            </div>

            <button @click="show = !show" type="button" class="infinite-scroll-footer__button">
                <img :class="{ 'rotate-180 transform': show }" :src="$assets.primary.arrowDown" alt="" />
                <span class="hidden md:block">{{ show ? 'Ocultar' : 'Mostrar' }} pie de página</span>
            </button>
        </div>

        <AppFooter v-if="show"></AppFooter>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Helpers } from '~/types/helpers'

const show = ref(false)

const props = defineProps({
    meta: {
        type: Object as PropType<Helpers.Meta>,
        required: true,
    },
})
const Route = useRoute()
const currentPage = computed(() => Number(Route.query?.page))
</script>

<style lang="postcss">
.infinite-scroll-footer {
    @apply fixed bottom-0 left-0 right-0 w-full border-t border-gray-300 bg-white;
    &__container {
        @apply container flex items-center justify-between py-4;
    }
    &__button {
        @apply flex items-center space-x-2 rounded-md px-5 py-2 hover:bg-black hover:bg-opacity-10;
        &:first-of-type {
            img {
                @apply rotate-180 transform;
            }
        }
    }
    &__pagination {
        @apply flex items-center space-x-3 font-medium;
        .next-page,
        .prev-page {
            @apply block h-5 w-5;
            img {
                @apply h-full w-full object-contain;
            }
        }
        .last-page,
        .first-page {
            @apply text-site-primary;
        }
    }
}
</style>
