<template>
    <hr ref="$scrollMarker" class="block h-0 border-0" />
</template>

<script lang="ts" setup>
const props = defineProps({
    page: {
        type: Number,
        required: true,
    },
})

const $scrollMarker = ref(null) as Ref<HTMLHRElement | null>

const Router = useRouter()

const visible = ref(false)

const intersectionObserverCallback = (objectEntry: IntersectionObserverEntry) => {
    if (objectEntry.isIntersecting) {
        Router.push({
            query: {
                page: props.page + 1,
            },
        })
    }
    if (
        !objectEntry.isIntersecting &&
        0 < objectEntry.boundingClientRect.top &&
        objectEntry.boundingClientRect.top > document.documentElement.clientHeight
    ) {
        Router.push({
            query: {
                page: props.page === 1 ? undefined : props.page,
            },
        })
        visible.value = false
    }
}

const { $scrollObserver } = useNuxtApp()

onMounted(async () => {
    await nextTick()

    if ($scrollMarker.value) $scrollObserver.addTarget($scrollMarker.value, intersectionObserverCallback)
})

onBeforeUnmount(() => {
    if ($scrollMarker.value) $scrollObserver.remove($scrollMarker.value)
})
</script>
